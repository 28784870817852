import React, { useState, createContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import getCompany from './services/company';
import { CompanyContext } from './contexts/CompanyContaxt';
import { User, Authenticated } from './contexts/SessionContext'

import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-slideshow-image/dist/styles.css';
import "./assets/css/nucleo-icons.css";
import 'leaflet/dist/leaflet.css';
import '../node_modules/react-toastify/scss/main.scss';
import "./assets/css/blk-design-system-pro-react.css";
import './assets/css/main.css';
import './assets/css/box.css';
import './assets/scss/main.scss';


import LandingPage from './views/LandingPage';
import Events from './views/events/Events';
import Event from './views/events/Event';
import Contact from './views/Contact';
import Login from './views/Login';
import Rental from './views/rental/Rental';
import Book from './views/rental/Book';
import GalleryList from './views/albums/List';
import Album from './views/albums/Album';
import Organizations from './views/events/Organizations';
import Analytics from './services/Analytics';
import 'react-loading-skeleton/dist/skeleton.css'
import 'sweetalert2/src/sweetalert2.scss'

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Conformation from './views/events/Conformation';
import QrLink from './views/subscribers/QrLink';
import Faq from './views/Faq';
import Partnership from './Partnership';
import getURLParameter from './helpers/getURLParameter';
import { AffContext } from './contexts/AffiliateContext';
import Terms from './views/Terms';
import Unsubscribe from './views/Unsubscribe';


export const CompanyServices = createContext();

function App() {
  const [company, setCompany] = useState({});
  const [user, setUser] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const userContexts = { user, setUser };
  const authenticatedContexts = { authenticated, setAuthenticated };
  const [aff, setAff] = useState('');
  const affContexts = { aff, setAff };
  // eslint-disable-next-line no-unused-vars
  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_live_eHPsjUYyy6mpbgQRFUWoy44x', {
  // const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_test_ERNCnPwW7Sbm9E4BUbPZdZqO', {
    stripeAccount: 'acct_1OcyblLOPcQFYVEk'
  }))


  useEffect(() => {
    Analytics();
  });

  useEffect(() => {
    const parm = getURLParameter('aff');
    if (parm) {
      setAff(parm);
    }
  }, []);

  useEffect(() => {
    async function getCompanyData() {
      let myCompany = await getCompany() || {};
      setCompany(myCompany);
    }
    getCompanyData()
  }, [setCompany]);

  return (
    <CompanyContext.Provider className="index-page account-settings sections-page" value={company}>
      <AffContext.Provider value={affContexts}>
        <Authenticated.Provider value={authenticatedContexts}>
          <User.Provider value={userContexts}>
            <Elements stripe={stripePromise}>
              <BrowserRouter>
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={(props) => <LandingPage {...props} />}
                  />
                  <Route
                    path="/contact-us"
                    exact
                    render={(props) => <Contact {...props} />}
                  />
                  <Route
                    path="/unsubscribe"
                    exact
                    render={(props) => <Unsubscribe {...props} />}
                  />
                  <Route
                    path="/faq"
                    exact
                    render={(props) => <Faq {...props} />}
                  />
                  <Route
                    path="/partnership"
                    exact
                    render={(props) => <Partnership {...props} />}
                  />
                  <Route
                    path="/events"
                    exact
                    render={(props) => <Organizations {...props} />}
                  />
                  <Route
                    path="/events/:slug"
                    exact
                    render={(props) => <Events {...props} />}
                  />
                  <Route
                    path="/events/event/:slug"
                    exact
                    render={(props) => <Event {...props} />}
                  />
                  <Route
                    path="/events/confirmation/:user/:event"
                    exact
                    render={(props) => <Conformation {...props} />}
                  />
                  <Route
                    path="/special_events/:slug"
                    exact
                    render={(props) => <Events {...props} />}
                  />
                  <Route
                    path="/rental"
                    exact
                    render={(props) => <Rental {...props} />}
                  />
                  <Route
                    path="/booking"
                    exact
                    render={(props) => <Book {...props} />}
                  />
                  <Route
                    path="/gallery/:org"
                    exact
                    render={(props) => <GalleryList {...props} />}
                  />
                  <Route
                    path="/gallery/:org/:slug"
                    exact
                    render={(props) => <Album {...props} />}
                  />
                  <Route
                    path="/terms"
                    exact
                    render={(props) => <Terms {...props} />}
                  />
                  <Route
                    path="/create_user/:id"
                    exact
                    render={(props) => <QrLink {...props} />}
                  />
                  <Route
                    path='/login'
                    exact
                    render={(props) => <Login {...props} />}
                  />
                  <Redirect to="/" />
                </Switch>
                <ToastContainer />
              </BrowserRouter>
            </Elements>
          </User.Provider>
        </Authenticated.Provider>
      </AffContext.Provider>
    </CompanyContext.Provider>
  );

}

export default App;

