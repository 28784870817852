// HalloweenButton.js
import React from 'react';
import './Halloween.css';
import { Link } from "react-router-dom";

const HalloweenButton = ({name, route}) => {
    return (
        <Link to={route} className="halloween-button">
            {name}
            <div className="cobweb top-left"></div>
            <div className="cobweb top-right"></div>
            <div className="cobweb bottom-left"></div>
            <div className="cobweb bottom-right"></div>
        </Link>
    );
}

export default HalloweenButton;
