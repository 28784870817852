import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { CompanyContext } from "../contexts/CompanyContaxt";
import Footer from "../components/Footer";
import { getServer, postServer } from "../services/server";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Unsubscribe = () => {
  const company = useContext(CompanyContext);
  const [email, setEmail] = useState("");
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [orgs, setOrgs] = useState([]);
  // get size of the window width
  // eslint-disable-next-line no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (!company?._id) return;
    async function fetchData() {
      const res = await getServer(`/events/getPromoBanners/${company?._id}`);
      const data = await res.map((event) => event.promo);
      setOrgs(data);
    }
    fetchData();
  }, [company?._id]);


  const handleUnsubscribe = (e) => {
    e.preventDefault();
    if (!email) return toast.error("Please enter your email address.");


    postServer("/emailBlasts/unsubscribe", {
      email,
      company: company?._id,
    })
    .then((res) => {
      if (res.status === 404) return alert("Email not found. Try another email or check the spelling.");
       
      setUnsubscribed(true);
      toast.success("You have been unsubscribed.");
    })
    .catch((err) => {
      setEmail(null);
      toast.error("Code 404: Email not found. Try another email or check the spelling.");
    });
  };

  return (
    <>
      <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Animated background elements */}
      {orgs.map((org, i) => (
        <div
          key={i}
          className="floating-circles"
          style={{
            position: "absolute",
            top: `${Math.floor(Math.random() * 100)}%`,
            left: `${Math.floor(Math.random() * ((i + 0.5 )* 20))}%`,
            width: windowWidth > 768 ? "125px" : "75px",
            height: windowWidth > 768 ? "125px" : "75px",
            opacity: "0.8",
            filter: "brightness(0.3)",
            animation: "float 60s ease-in-out infinite",
          }}
        >
          <img src={org?.story || org?.banner || org?.post} alt="Organization Logo" style={{ width: "100%" }} />
        </div>
      ))}

      <Container>
        <Row className="justify-content-center text-center">
          <Col md={6}>
            {unsubscribed ? (
              <div>
                <h1 style={{ fontSize: "2.5rem", color: "#dc3545" }}>
                  😢 You'll be missed... 😢
                </h1>
                <p>
                  You’ve been unsubscribed. 😭
                </p>
                <Button as={Link} variant="danger" to="/">
                  Go Home
                </Button>
              </div>
            ) : (
              <div>
                <h1 style={{ fontSize: "2.5rem", color: "#dc3545" }}>
                  Wait! Are you sure? 😟
                </h1>
                <p>We’ll miss you... but if you must go, enter your email below to unsubscribe:</p>
                <Form onSubmit={handleUnsubscribe}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      style={{
                        padding: "10px",
                        fontSize: "1.2rem",
                        marginBottom: "20px",
                        color: "white",
                      }}
                    />
                  </Form.Group>
                  <Button variant="danger" type="submit" size="lg" block>
                    Yes, unsubscribe me 😢
                  </Button>
                </Form>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {/* Floating animation */}
      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0); }
            50% { transform: translateY(-510px); }
            100% { transform: translateY(0); }
          }
        `}
      </style>
    </div>
    <Footer />
    </>
  );
};

export default Unsubscribe;
