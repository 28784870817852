import React, { useContext, useEffect, useState } from "react";
// ReactJS plugin for a nice carousel
import { useParams } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import Analytics from "../../services/Analytics";
import { getServer } from "../../services/server";
import moment from "moment";
import TicketCart from "../../components/TicketCart";
import { CompanyContext } from "../../contexts/CompanyContaxt";
import ticketPriceMinMax from "../../helpers/ticketPriceMinMax";
import { AffContext } from "../../contexts/AffiliateContext";
import trackingLink from "../../services/trackingLink";
import EventHeader from "../../components/headers/EventHeader";
import usdFormatter from "../../helpers/usdFormatter";
import H2OPROMO from '../../assets/promo/h2o/h2oPromo1.png'

export default function Event() {
  const { slug } = useParams();
  const { aff } = useContext(AffContext);
  const company = useContext(CompanyContext);
  const [show, setShow] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pageData, setPageData] = useState({});
  const wrapper = React.useRef(null);
  const [ticketQuantities, setTicketQuantities] = useState({});
  const [displayTicketPrices, setDisplayTicketPrices] = useState({}); // minPrice, maxPrice
  // eslint-disable-next-line no-unused-vars
  const [eventLink, setEventLink] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [didAff, setDidAff] = useState(false);
  const [affId, setAffId] = useState(null);

  function getURLParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  useEffect(() => {
    async function sendData() {
      try {
        if (aff && pageData?._id) {
          await trackingLink(pageData?._id, aff).then(async (res) => await setAffId(res?._id || null));
          setDidAff(true);
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    sendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aff, pageData]);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);

  useEffect(() => {
    Analytics();
    async function getData() {
      try {
        const response = await getServer(`/events/getEvent/${slug}`);
        if (response) {
          setPageData(response);
          setCurrentTicket(response);
          setEventLink(response?.ticket_link);
          setDisplayTicketPrices(ticketPriceMinMax(response?.ticketinventory || []));
          const link = getURLParameter('aff');
          let mainlink = response?.ticket_link;
          let newlink;
          if (link) {
            let newlink = mainlink + '?aff=' + link;
            setEventLink(newlink);
          } else {
            newlink = '?aff=Website';
            setEventLink(newlink);
          }
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    getData();
  }, [slug, aff, pageData?._id]);




  return (
    <>
      <NavBar />

      <div className="wrapper" ref={wrapper}>
        <EventHeader image={pageData?.promo?.banner} />
        <div className="section">
          <Container>
            <Row>
              <Col md={3}>
                <div className="card-image">
                  <a href="#!">

                    {
                      pageData?.promo?.video ? (
                        <video autoPlay muted loop playsInline className="VideoFlyer">
                          <source src={pageData?.promo?.video} type="video/mp4" />
                        </video>
                      ) : (<img className="img rounded" src={pageData?.promo?.story || pageData?.promo?.post} alt={pageData?.name} />)
                    }

                  </a>
                </div>
              </Col>
              <Col className="ml-auto mr-auto margin-top-40" md="6">
                <Row>
                  <Col md={12}>
                    <h3 style={{ paddingBottom: 5 }} className="event-date text-white">{moment(pageData?.start_date).format('lll')}</h3>
                    <br />
                    <h1 className="card-title">
                      {pageData?.link && pageData?.link !== '' ? (
                        <a target="_BLANK" href={pageData?.link} rel="noreferrer" style={{ fontFamily: 'mainFont' }}>{pageData?.organization?.name} - {pageData?.name}</a>
                      ) : (
                        <a href="#!" style={{ fontFamily: 'mainFont' }}>{pageData?.organization?.name} - {pageData?.name}</a>
                      )}
                    </h1>
                  </Col>
                  <Col md={12}>
                    {/* {pageData?.details} */}
                    {pageData?.details && (
                      <div className="event-details">
                        <div dangerouslySetInnerHTML={{ __html: pageData?.details.replace(/background-color:/g, '').replace(/color:/g, '') }} />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={3} className="margin-top-40">
                <Link className="d-none d-md-block" to="/events/event/halloween_20241718378854522">
                  <img style={{objectFit: 'cover', width: '100%', height: '150px'}} src={H2OPROMO} alt="logo" />
                </Link>
                <Card
                  body
                  className="my-2 event-button"
                >

                  {pageData?.ticketinventory && Array.isArray(pageData?.ticketinventory) && pageData?.ticketinventory.length > 0 ? (
                    <>
                      <h3 style={{ fontWeight: 600 }} className="text-white hidden-mobile">
                        <span style={{ fontSize: 14, fontWeight: 200 }}>Starting at</span> <br /> {usdFormatter(displayTicketPrices.minPrice)} - {usdFormatter(displayTicketPrices.maxPrice)}
                      </h3>
                    </>

                  ) : (
                    <>
                      {pageData.ticket_link ? (
                        <h1 style={{ fontWeight: 800 }} className="text-white">
                          <span style={{ fontSize: 18, fontWeight: 200 }}>Link to Tickets</span>
                        </h1>
                      ) : null}
                    </>
                  )}
                  {moment(pageData?.end_date).isAfter(moment()) ? (
                    <>
                      {pageData?.ticketinventory && Array.isArray(pageData?.ticketinventory) && pageData?.ticketinventory.length > 0 ? (
                        <TicketCart
                          show={show}
                          event={pageData}
                          handleClose={handleClose}
                          handleShow={handleShow}
                          currentTicket={currentTicket}
                          company={company}
                          ticketQuantities={ticketQuantities}
                          setTicketQuantities={setTicketQuantities}
                          affId={affId}
                        />
                      ) : (
                        <>
                          {pageData.ticket_link ? (
                            <a style={{ width: '100%' }} target="_BLANK" href={pageData?.ticket_link} rel="noreferrer">
                              <Button color="primary" block>Tickets</Button>
                            </a>
                          ) : <Button color="secondary" disabled block>Unavailable</Button>}
                        </>
                      )}
                    </>
                  ) : (
                    <Button color="secondary" disabled block>Event Ended</Button>
                  )}
                </Card>
                <div className="author text-center">
                  <div>
                    <div style={{ color: 'white', fontSize: 15 }}><span style={{ fontWeight: 'bold', fontSize: 18, color: 'red', marginRight: 3 }}>{pageData?.organization?.age}</span> {moment(pageData?.start_date).format('lll')}</div>
                  </div>
                  <address>
                    <a href={`//maps.apple.com/?q=${pageData?.organization?.address}},${pageData?.organization?.city},${pageData?.organization?.state},${pageData?.organization?.zip}`}>
                      {pageData?.organization?.address},<br />
                      {pageData?.organization?.city} {pageData?.organization?.state}, {pageData?.organization?.zip}
                    </a>
                  </address>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="section">
          <Container>
            <h1 style={{ fontFamily: 'mainFont' }}> Recent Gallery </h1>
            <Slick {...slickSettings}>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/mark-harrison.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/steven-roe.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/rezaul-karim.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/pawel-nolbert.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/serge-kutuzov.jpg")}
                  width="450"
                />
              </div>
            </Slick>
          </Container>
        </div> */}
        <Footer />
      </div >
    </>
  );
}
